module.exports = [{
      plugin: require('/var/www/clients/client2/web10/web/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-50066192-2","head":true},
    },{
      plugin: require('/var/www/clients/client2/web10/web/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"354083421809768"},
    },{
      plugin: require('/var/www/clients/client2/web10/web/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"oxy2mpb","providerA":"gimlet-display, serif","providerB":"proxima-nova, sans-serif"}},
    },{
      plugin: require('/var/www/clients/client2/web10/web/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
