// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/var/www/clients/client2/web10/web/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-design-jsx": () => import("/var/www/clients/client2/web10/web/src/pages/design.jsx" /* webpackChunkName: "component---src-pages-design-jsx" */),
  "component---src-pages-about-jsx": () => import("/var/www/clients/client2/web10/web/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-carrer-list-jsx": () => import("/var/www/clients/client2/web10/web/src/pages/carrer-list.jsx" /* webpackChunkName: "component---src-pages-carrer-list-jsx" */),
  "component---src-pages-realizations-jsx": () => import("/var/www/clients/client2/web10/web/src/pages/realizations.jsx" /* webpackChunkName: "component---src-pages-realizations-jsx" */),
  "component---src-pages-furnitures-jsx": () => import("/var/www/clients/client2/web10/web/src/pages/furnitures.jsx" /* webpackChunkName: "component---src-pages-furnitures-jsx" */),
  "component---src-pages-news-jsx": () => import("/var/www/clients/client2/web10/web/src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-contact-jsx": () => import("/var/www/clients/client2/web10/web/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-carrer-jsx": () => import("/var/www/clients/client2/web10/web/src/pages/carrer.jsx" /* webpackChunkName: "component---src-pages-carrer-jsx" */),
  "component---src-pages-page-jsx": () => import("/var/www/clients/client2/web10/web/src/pages/page.jsx" /* webpackChunkName: "component---src-pages-page-jsx" */),
  "component---src-pages-post-jsx": () => import("/var/www/clients/client2/web10/web/src/pages/post.jsx" /* webpackChunkName: "component---src-pages-post-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("/var/www/clients/client2/web10/web/src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-carrer-single-jsx": () => import("/var/www/clients/client2/web10/web/src/pages/carrer-single.jsx" /* webpackChunkName: "component---src-pages-carrer-single-jsx" */),
  "component---src-pages-404-jsx": () => import("/var/www/clients/client2/web10/web/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("/var/www/clients/client2/web10/web/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

